// Generated by Framer (d19f530)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["W8yJIUohe", "f93ucwbkt", "aJnqi5gFD", "pz_yFQDhk", "zciVyymvU"];

const variantClassNames = {aJnqi5gFD: "framer-v-12l7en9", f93ucwbkt: "framer-v-1y1dsh9", pz_yFQDhk: "framer-v-1tpaaey", W8yJIUohe: "framer-v-12mm308", zciVyymvU: "framer-v-1eyhu1u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"2XS": "zciVyymvU", "Extra small": "pz_yFQDhk", Large: "W8yJIUohe", Medium: "f93ucwbkt", Small: "aJnqi5gFD"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "W8yJIUohe", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "W8yJIUohe", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-97Rve", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-12mm308", className)} data-framer-name={"Large"} layoutDependency={layoutDependency} layoutId={"W8yJIUohe"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({aJnqi5gFD: {"data-framer-name": "Small"}, f93ucwbkt: {"data-framer-name": "Medium"}, pz_yFQDhk: {"data-framer-name": "Extra small"}, zciVyymvU: {"data-framer-name": "2XS"}}, baseVariant, gestureVariant)}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-97Rve [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-97Rve .framer-genska { display: block; }", ".framer-97Rve .framer-12mm308 { height: 120px; position: relative; width: 120px; }", ".framer-97Rve.framer-v-1y1dsh9 .framer-12mm308 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 80px); width: 80px; }", ".framer-97Rve.framer-v-12l7en9 .framer-12mm308 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 64px); width: 64px; }", ".framer-97Rve.framer-v-1tpaaey .framer-12mm308 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 40px); width: 40px; }", ".framer-97Rve.framer-v-1eyhu1u .framer-12mm308 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); width: 32px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 120
 * @framerIntrinsicWidth 120
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"f93ucwbkt":{"layout":["fixed","fixed"]},"aJnqi5gFD":{"layout":["fixed","fixed"]},"pz_yFQDhk":{"layout":["fixed","fixed"]},"zciVyymvU":{"layout":["fixed","fixed"]}}}
 */
const FramerXCSyOfLOe: React.ComponentType<Props> = withCSS(Component, css, "framer-97Rve") as typeof Component;
export default FramerXCSyOfLOe;

FramerXCSyOfLOe.displayName = "Elemental/ V Padding";

FramerXCSyOfLOe.defaultProps = {height: 120, width: 120};

addPropertyControls(FramerXCSyOfLOe, {variant: {options: ["W8yJIUohe", "f93ucwbkt", "aJnqi5gFD", "pz_yFQDhk", "zciVyymvU"], optionTitles: ["Large", "Medium", "Small", "Extra small", "2XS"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerXCSyOfLOe, [])